import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"id":"app-bar","absolute":"","app":"","color":"transparent","flat":"","height":"75"}},[_c(VBtn,{staticClass:"mr-3",attrs:{"elevation":"1","fab":"","small":""},on:{"click":function($event){return _vm.setDrawer(!_vm.drawer)}}},[(_vm.value)?_c(VIcon,[_vm._v(" mdi-view-quilt ")]):_c(VIcon,[_vm._v(" mdi-dots-vertical ")])],1),_c(VToolbarTitle,{staticClass:"hidden-sm-and-down font-weight-light",attrs:{"data-cy":"page-title"},domProps:{"textContent":_vm._s(_vm.$route.meta.title)}}),_c(VSpacer),_c('div',{staticClass:"mx-3"}),_c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on))]}}])},[_c(VList,{attrs:{"tile":false,"nav":""}},[_c('div',_vm._l((_vm.notifications),function(n,i){return _c('app-bar-item',{key:("item-" + i)},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(n)}})],1)}),1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }